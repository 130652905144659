import { useState } from 'react'
import { Select } from '@mantine/core'
import { useForm } from '@mantine/form'
import axios from '../../tools/axiosWrapper'
import _ from 'lodash'

const WIDTH = 280

const Dropdown = ({ SELECTSTYLES, counties, val, change, place }) => {
  return (
    <>
      <div
        className='flex'
        style={{
          width: WIDTH,
        }}
      >
        <Select
          style={{
            width: WIDTH - 32,
          }}
          onChange={change}
          data={counties}
          placeholder={place}
          dropdownPosition={'bottom'}
          searchable
          nothingFound='Nothing found'
          value={val}
          styles={{
            input: {
              color: '#EE791F',
              border: 'none',
              borderRadius: 0,
              ...SELECTSTYLES,
            },
            item: {
              lineHeight: '32px',
              ...SELECTSTYLES,
            },
            selected: { height: 50 },
          }}
        />
      </div>
    </>
  )
}

export default Dropdown
