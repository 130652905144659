import { useEffect, useState, useRef } from 'react'
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import zooms from './stateZooms.json'
import _ from 'lodash'

const Mapbox = ({ state, HEIGHT, WIDTH }) => {
  mapboxgl.accessToken =
    'pk.eyJ1IjoicGRlbHBlYnJpY2UiLCJhIjoiY2w3ZzlzOHR5MDJnOTNwbzNzZWZzZWp2eiJ9.29F2_IusbUOtogw2Lscctg'

  const mapContainer = useRef(null)
  const map = useRef(null)

  const [zoom, setZoom] = useState([2.6, -96, 39])

  useEffect(() => {
    if (map.current) return

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/emunn/cl6p1q2r1001z14n05os6xxao',
      center: [-96.4, 39],
      zoom: 2.95,
      projection: {
        name: 'albers',
        center: [-96, 30],
      },
    })
  }, [])

  useEffect(() => {
    if (state) {
      const current = _.find(zooms, _.matchesProperty('state', state))
      map.current.flyTo({
        zoom: current.zoom ? current.zoom[0] : 1.5,
        center: current.zoom ? [current.zoom[1], current.zoom[2]] : [-96.4, 39],
        speed: 2,
        curve: 1,
        easing: (t) => t,
        essential: true,
      })
    }
  }, [state])

  return (
    <>
      <div
        style={{
          height: HEIGHT,
          width: WIDTH,
          marginTop: 10,
        }}
        ref={mapContainer}
        className='map-container'
      />
    </>
  )
}

export default Mapbox
