import React from 'react'
import { geoCentroid } from 'd3-geo'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from 'react-simple-maps'
import { motion } from 'framer-motion'

import State from './state'

import allStates from './allstates.json'

const geoUrl = 'https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json'

const RADIUS = 16
const Map = ({ state, setState }) => {
  return (
    <>
      <div className='map'>
        <ComposableMap projection='geoAlbersUsa'>
          <Geographies geography={geoUrl} width={800}>
            {({ geographies }) => (
              <>
                {geographies.map((geo) => (
                  <State geo={geo} state={state} setState={setState} />
                ))}
                {geographies.map((geo) => {
                  const centroid = geoCentroid(geo)
                  const cur = allStates.main.find((s) => s.val === geo.id)
                  return (
                    <g key={geo.rsmKey + '-name'} className={'map-labels'}>
                      {cur && centroid[0] > -160 && centroid[0] < -67 && (
                        <Marker
                          coordinates={
                            cur.off
                              ? [
                                  centroid[0] + cur.off[0],
                                  centroid[1] + cur.off[1],
                                ]
                              : centroid
                          }
                        >
                          <text
                            y='2'
                            textAnchor='middle'
                            fill={
                              geo.properties.name == state ? '#FFF' : '#888'
                            }
                          >
                            {cur.id}
                          </text>
                        </Marker>
                      )}
                    </g>
                  )
                })}
              </>
            )}
          </Geographies>
        </ComposableMap>
        <svg
          className={'map-ne'}
          width={RADIUS * 2}
          height={allStates.ne.length * (RADIUS * 2 + 2)}
        >
          {allStates.ne.map((d, i) => (
            <g>
              <circle
                onMouseUp={() => {
                  setState(d.name)
                }}
                cx={RADIUS}
                cy={RADIUS + i * (RADIUS * 2 + 2)}
                r={RADIUS}
                fill={d.name == state ? '#FF9C50' : '#D8DFEB'}
              ></circle>
              <text
                className={'map-labels'}
                x={RADIUS}
                y={RADIUS + i * (RADIUS * 2 + 2) + 1}
                fill={d.name == state ? '#FFF' : '#888'}
                textAnchor='middle'
                alignmentBaseline='middle'
              >
                {d.id}
              </text>
            </g>
          ))}
        </svg>
      </div>
    </>
  )
}

export default Map
