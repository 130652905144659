import './header.css'
import { useState, useEffect, useContext } from 'react'

import anime from 'animejs/lib/anime.es.js'

import { Outlet, Link, useNavigate } from 'react-router-dom'
import { Language } from '../context/language'

import techlab from '../media/techlab.svg'
import gov1430 from '../media/gov1430.png'
import mydatacan from '../media/mydatacan.png'
import dataprivacylab from '../media/dataprivacylab.png'
import { languages } from '../context/languages'
import axios from '../tools/axiosWrapper'

const Header = ({ auth }) => {
  let navigate = useNavigate()
  const { language, set_language } = useContext(Language)
  useEffect(() => {
    anime
      .timeline({
        easing: 'easeInOutSine',
      })
      .add({
        targets: '#logocard',
        duration: 2000,
        points: [
          {
            value:
              '38.018 28.046 47.564 11.704 30.065 1.482 15.981 25.593 33.893 35.769 38.018 28.046',
          },
          {
            value:
              '38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372',
          },
        ],
      })
  }, [])
  const logoHover = () => {
    const up = anime
      .timeline({
        easing: 'easeInOutSine',
        loop: false,
      })
      .add({
        targets: '#logocard',
        duration: 800,
        points: [
          {
            value:
              '38.018 28.046 47.564 11.704 30.065 1.482 15.981 25.593 33.893 35.769 38.018 28.046',
          },
          {
            value:
              '38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372',
          },
        ],
      })
  }

  const onClickLoginButton = async () => {
    try {
      const { data } = await axios.get('/auth/login')
      window.location = data.login_url
    } catch (error) {
      console.log(error)
    }
  }

  const onClickSignupButton = async () => {
    try {
      const { data } = await axios.get('/auth/login')
      let url = data.login_url
      url = url.replace('/login', '/signup')
      window.location = url
    } catch (error) {
      console.log(error)
    }
  }

  const clickLogout = () => {
    axios.get('/auth/logout').then((res) => {
      window.location = '/'
    })
  }

  return (
    <>
      <header>
        <div className={'header-left'}>
          <div
            onMouseEnter={() => logoHover()}
            onClick={() => navigate('/')}
            className='logo'
          >
            <svg className='logo' viewBox='0 0 56.277 71.993'>
              <g>
                <path
                  className='logoa'
                  d='M54.988,44.924,45.745,29.646a2,2,0,0,0-1.722-.965l-29.742.156a2,2,0,0,0-1.6.809L1.394,44.871A2,2,0,0,0,1,46.063v22.93a2,2,0,0,0,2,2H53.277a2,2,0,0,0,2-2V45.96A2,2,0,0,0,54.988,44.924Z'
                />
                <line
                  className='logob'
                  x1='40.675'
                  y1='36.372'
                  x2='16.675'
                  y2='36.372'
                />
                <polygon
                  id='logocard'
                  class='logoa'
                  points='38.018 36.372 47.564 20.029 30.065 9.808 15.981 33.918 20.182 36.372 38.018 36.372'
                />

                <line
                  className='logoa'
                  x1='55.277'
                  y1='45.402'
                  x2='1'
                  y2='45.402'
                />
                <path
                  className='logoc'
                  d='M15.981,0c1.962,5.394,4.414,7.846,9.808,9.808-5.394,1.961-7.846,4.413-9.808,9.807-1.961-5.394-4.413-7.846-9.807-9.807C11.568,7.846,14.02,5.394,15.981,0Z'
                />
                <path
                  className='logoc'
                  d='M41.68,18.626a5.373,5.373,0,0,0,1.495,5.061,5.373,5.373,0,0,0-5.061,1.495,5.373,5.373,0,0,0-1.495-5.061A5.373,5.373,0,0,0,41.68,18.626Z'
                />
              </g>
            </svg>
            <div className='logo-text'>
              Vote
              <b className='logo-bold'>
                Flare<sup class='trademark'>TM</sup>
              </b>
            </div>
          </div>

          <div className='languages'>
            <div
              onClick={() => set_language(languages[0])}
              style={{
                background: language.lang == 'en' ? '#000654' : 'none',
                color: language.lang == 'en' ? 'white' : '#000654',
              }}
            >
              EN
            </div>
            <div
              onClick={() => set_language(languages[1])}
              style={{
                color: language.lang == 'es' ? 'white' : '#000654',
                background: language.lang == 'es' ? '#000654' : 'none',
              }}
            >
              ES
            </div>
          </div>
        </div>
        {auth ? (
          <button onClick={clickLogout} className='login'>
            logout
          </button>
        ) : (
          <div className='right'>
            <button onClick={onClickLoginButton} className='login'>
              {language.login}
            </button>
          </div>
        )}
      </header>

      <div className='all'>
        <Outlet />
        <footer>
          <div className='top'>
            <Link to='about'>{language.header.about}</Link>
            <a
              target={'_blank'}
              href={
                'https://docs.google.com/forms/d/e/1FAIpQLSeqxVoz8UwO6DYPvVeZWLrbMJTO9d19CzCP5G4Zy3l5bEasXg/viewform'
              }
            >
              {language.header.contact}
            </a>
            <Link to='history'>{language.header.history}</Link>

            <Link to='terms'>{language.header.terms}</Link>
          </div>
          <div className='break'></div>
          <div className='bottom'>
            <img src={techlab} id='techlab' />
            <img src={gov1430} id='gov1430' />
            <img src={mydatacan} id='mydatacan' />
            <img src={dataprivacylab} id='dataprivacylab' />
          </div>
        </footer>
      </div>
    </>
  )
}

export default Header
