import { useState } from 'react'
import { Select } from '@mantine/core'
import counties from '../media/data/counties.json'
import Mapbox from './map'
import axios from '../tools/axiosWrapper'
import { motion } from 'framer-motion'
import { randomInt } from 'd3'

import './dashboard.css'
import _ from 'lodash'

import OHIO from './dropdowns/OH.json'
import Dropdown from './dropdowns/dropdown'
import Pie from './dropdowns/pie'

const HEIGHT = 380
const WIDTH = 480

const Dashboard = () => {
  const [dashstate, setDashstate] = useState('Ohio')
  const [dashcounty, setDashcounty] = useState('')

  // const data = _.map(county_geo.features, (obj) => {
  //   const pop = _.find(county_pop, { GEO_ID: obj.properties.GEO_ID })
  //   return _.assign({}, obj, { pop })
  // })
  axios.get('/dashboard/info').then((res) => {
    console.log(res)
  })

  const SELECTSTYLES = {
    backgroundColor: 'transparent',
    height: 50,
    fontFamily: "'Tungsten A', 'Tungsten B'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '30px',
  }

  const stat = [
    { stat: 'percent change', number: randomInt(1, 5)() + '%' },
    { stat: 'percent flares', number: randomInt(0, 2)() + '%' },
    { stat: 'total change', number: randomInt(50, 200)() },
    {
      stat: `total voters monitored in ${dashstate}`,
      number: randomInt(90, 98)() + '%',
    },
  ]

  return (
    <main className='dashboard'>
      <div
        style={{ marginTop: 50, border: '1px solid black', borderRadius: 10 }}
      >
        <div className='horizontal'>
          <Dropdown
            SELECTSTYLES={SELECTSTYLES}
            counties={_.keys(counties)}
            val={dashstate}
            change={(v) => setDashstate(v)}
            place={'state'}
          />
        </div>
        <div className='horizontal'>
          <Dropdown
            SELECTSTYLES={SELECTSTYLES}
            counties={dashstate ? counties[dashstate] : []}
            val={dashcounty}
            change={(v) => setDashcounty(v)}
            place={'county'}
          />
        </div>
        {/* <div className='horizontal'>
          <Dropdown
            SELECTSTYLES={SELECTSTYLES}
            counties={dashstate ? counties[dashstate] : []}
            val={dashcounty}
            change={(v) => setDashcounty(v)}
            place={'precinct'}
          />
        </div> */}
        <motion.div
          className={'flex tungsten'}
          onClick={() => {
            setDashstate(null)
            setDashcounty(null)
          }}
          style={{ originX: 0.5, originY: 0.5, cursor: 'pointer' }}
          whileTap={{ scale: 0.95 }}
        >
          <svg width={24} height={24} viewBox='0 0 128 128'>
            <circle cx={64} cy={64} fill={'#CCC'} r={64} />
            <g stroke='#FFF' strokeWidth='14' strokeLinecap='round'>
              <line x1='35.47' y1='35.47' x2='92.53' y2='92.53' />
              <line x1='35.47' y1='92.53' x2='92.53' y2='35.47' />
            </g>
          </svg>
          <div style={{ padding: '0px 10px', fontSize: 20, fontWeight: 600 }}>
            clear
          </div>
        </motion.div>
      </div>
      <div style={{ marginTop: 50 }}>
        <motion.div initial={{ scale: 0.8 }}>
          <Mapbox state={dashstate} HEIGHT={HEIGHT} WIDTH={WIDTH} />
        </motion.div>
        <motion.div
          className={'tungsten'}
          style={{
            width: 380,
            height: 340,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: 500,
              // textTransform: 'uppercase',
            }}
          >
            voter breakdown
          </div>
          {/* <Pie /> */}
          <div
            style={{
              marginTop: 40,
              width: 380,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {stat.map((d) => (
              <div
                style={{
                  width: 240,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-end',
                  fontSize: 30,
                  fontWeight: 500,
                  textTransform: 'uppercase',
                }}
              >
                <div style={{ paddingRight: 20 }}>{d.stat}</div>
                <div>{dashstate ? d.number : '-'}</div>
              </div>
            ))}
          </div>
        </motion.div>
      </div>
    </main>
  )
}

export default Dashboard
