import React, { useContext, useEffect, useState, useRef } from 'react'
import { SegmentedControl } from '@mantine/core'
import Required from './required'
import axios from '../../../../tools/axiosWrapper'
import { motion } from 'framer-motion'
import _ from 'lodash'

const InfoCard = ({
  language,
  form,
  metastate,
  track,
  focus,
  abbrevs,
  state,
  setProgress,
  setIdentity,
}) => {
  const [dirty, setDirty] = useState(false)

  const [addfields, setAddfields] = useState([])
  useEffect(() => {
    setAddfields(
      metastate && form.values.state2letter !== ''
        ? metastate[form.values.state2letter].inputs
        : ['']
    )
  }, [metastate])
  useEffect(() => {
    setAddfields(
      metastate && form.values.state2letter !== ''
        ? metastate[form.values.state2letter].inputs
        : ['']
    )
  }, [form.values.state2letter])

  useEffect(() => {
    if (localStorage.getItem('vf_temp') !== null) {
      const temp = JSON.parse(localStorage.getItem('vf_temp'))
      setDirty(
        temp.firstname != form.values.firstname ||
          form.values.firstname == '' ||
          temp.lastname != form.values.lastname ||
          form.values.lastname == '' ||
          temp.dd != form.values.dd ||
          form.values.dd == '' ||
          temp.mm != form.values.mm ||
          form.values.mm == '' ||
          temp.yyyy != form.values.yyyy ||
          form.values.yyyy == '' ||
          temp.zip5 != form.values.zip5 ||
          form.values.zip5 == '' ||
          temp.stateid != form.values.stateid ||
          form.values.stateid == '' ||
          temp.city != form.values.city ||
          form.values.city == '' ||
          temp.ssn4 != form.values.ssn4 ||
          form.values.ssn4 == '' ||
          temp.state2letter != form.values.state2letter ||
          form.values.state2letter == '' ||
          temp.county != form.values.county ||
          form.values.county == '' ||
          temp.state != form.values.state ||
          form.values.state == ''
          ? true
          : false
      )
    }
  }, [form.values])

  const mm = useRef()
  const dd = useRef()
  const yyyy = useRef()
  const zip5ref = useRef()

  const underline = (
    <svg width={360} height={4}>
      <rect width={360} height={4} fill={'#6F707A'}></rect>
    </svg>
  )

  return (
    <>
      <motion.div className={'card-main'}>
        <div className='direction'>
          <span>2 </span>enter voter info
        </div>
        <div className={'card-input'}>
          <div>
            <div className={'labelwrap'} style={{ margin: '0px 0px 20px 0px' }}>
              <div className={'label'}>
                <div className={'main-label'}>{'full name'}</div>
                <motion.input
                  {...focus}
                  required
                  placeholder=' FIRST'
                  {...form.getInputProps('firstname')}
                  style={{ width: '110px' }}
                />
                <motion.input
                  {...focus}
                  required
                  placeholder=' LAST'
                  {...form.getInputProps('lastname')}
                  style={{ width: '120px' }}
                />
              </div>
            </div>
            <div className={'labelwrap'}>
              <div className={'label'}>
                <label>{'birthdate'}</label>
                <motion.input
                  {...focus}
                  required
                  className={'date'}
                  ref={mm}
                  placeholder='MM'
                  type='number'
                  min='1'
                  max='12'
                  {...form.getInputProps('mm')}
                  style={{ margin: '0px 0px 0px 12px', textAlign: 'center' }}
                  onInput={(e) => {
                    if (e.currentTarget.value.length == 2) {
                      dd.current.focus()
                    }
                  }}
                />
                <motion.input
                  {...focus}
                  required
                  className={'date'}
                  ref={dd}
                  placeholder='DD'
                  type='number'
                  min='1'
                  max='31'
                  style={{ margin: '0px 0px 0px 10px', textAlign: 'center' }}
                  {...form.getInputProps('dd')}
                  onInput={(e) => {
                    if (e.currentTarget.value.length == 2) {
                      yyyy.current.focus()
                    }
                  }}
                />
                <motion.input
                  {...focus}
                  required
                  className={'date'}
                  ref={yyyy}
                  placeholder='YYYY'
                  type='number'
                  min='1850'
                  max={new Date().getFullYear()}
                  {...form.getInputProps('yyyy')}
                  style={{
                    width: '50px',
                    margin: '0px 0px 0px 10px',
                    textAlign: 'center',
                  }}
                  onInput={(e) => {
                    if (e.currentTarget.value.length == 4) {
                      zip5ref.current.focus()
                    }
                  }}
                />
              </div>
              {/* {underline} */}
            </div>
            <div className={'labelwrap'}>
              <div className={'label'}>
                <div className={'main-label'}>{'zipcode'}</div>
                <motion.input
                  {...focus}
                  required
                  placeholder={'· · · · ·'}
                  ref={zip5ref}
                  type='number'
                  style={{ width: '60px', margin: '0px 0px 0px 28px' }}
                  {...form.getInputProps('zip5')}
                />
              </div>
              {/* {underline} */}
            </div>
            {addfields.includes('streetaddress') ||
            addfields.includes('ssn4') ||
            addfields.includes('city') ||
            addfields.includes('stateid') ? (
              <h4>Your state requires additional info:</h4>
            ) : (
              ''
            )}
            <div className={'labelwrap'} style={{ margin: '0px 0px 20px 0px' }}>
              {addfields
                .filter((d) => {
                  return (
                    d != 'legalfirstname' &&
                    d != 'legallastname' &&
                    d != 'birthdate_yyyymmdd'
                  )
                })
                .map((d) => {
                  return <Required form={form} title={d} />
                })}
            </div>
          </div>
          {dirty && (
            <motion.div
              initial={{ x: -30, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              onClick={form.onSubmit((values) => {
                var state2 = _.invert(abbrevs)
                form.setFieldValue('state2letter', state ? state2[state] : '')
                setProgress(1)
                setIdentity(null)
                window.scroll(0, 500)
                let month = values.mm.length == 1 ? '0' + values.mm : values.mm
                let day = values.dd.length == 1 ? '0' + values.dd : values.dd
                values.birthdate = values.yyyy + month + day
                localStorage.setItem('vf_temp', JSON.stringify(values))
                axios
                  .post('/state50/voter_register50_update/', values)
                  .then((res) => {
                    console.log(res)
                    return axios.get('/state50/voter_lookup50_now/')
                  })
                  .then((res) => {
                    const denied =
                      res.data.split(' ')[7] == 'immediately' || res.data == ''
                        ? 1
                        : null
                    setIdentity(denied)
                    var ret =
                      denied !== 1
                        ? axios.get('/state50/voter_lookup_last_encrypt/')
                        : 'no'
                    setProgress(4)
                    return ret
                  })
                  .then((res) => {
                    setProgress(5)
                    setIdentity(
                      res == 'no' || res.data.info.addressinfo == ''
                        ? 'no'
                        : res.data
                    )
                  })
              })}
              className={'update'}
            >
              update
            </motion.div>
          )}
        </div>
      </motion.div>
    </>
  )
}
export default InfoCard
