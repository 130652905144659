import { useEffect, useState, useRef } from 'react'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Annotation,
} from 'react-simple-maps'

const State = ({ geo, state, setState }) => {
  const [select, setSelect] = useState(0)

  const ref = useRef()

  return (
    <g
      ref={ref}
      className={`state`}
      onMouseUp={() => {
        setState(geo.properties.name)
      }}
    >
      <Geography
        key={geo.rsmKey}
        stroke={'#ECF2FF'}
        fill={geo.properties.name == state ? '#FF9C50' : '#D8DFEB'}
        strokeWidth={1}
        geography={geo}
      />
    </g>
  )
}

export default State
