import { useState, useEffect, useContext, useMemo } from 'react'
import { render } from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './home/home'
import Header from './header/header'
import Other from './other/other'
import History from './other/history'
import Terms from './other/terms'
import Lookup from './forms/main/lookup'
import Portal from './portal/portal'
import axios from './tools/axiosWrapper'

import Dashboard from './dashboard/dashboard'

import { Language } from './context/language'
import { languages } from './context/languages'
import { Global } from '@mantine/core'
import { Button, Code, Title, MantineProvider } from '@mantine/core'

const App = () => {
  const [language, set_language] = useState(languages[0])
  const [auth, setAuth] = useState(false)

  const value = useMemo(
    () => ({ language, set_language }),
    [language, set_language]
  )

  useEffect(() => {
    axios.get('/auth/me').then((res) => {
      const { data } = res
      setAuth(data.id ? 1 : 0)
    })
  }, [])

  return (
    <>
      <MantineProvider>
        <BrowserRouter>
          <Language.Provider value={value}>
            <Routes>
              <Route path='/' element={<Header auth={auth} />}>
                <Route index element={<Home />} />
                <Route
                  path='/about'
                  element={<Other page={language.about} />}
                />
                <Route path='/terms' element={<Terms />} />
                <Route path='/history' element={<History />} />
                <Route path='/lookup' element={<Lookup auth={auth} />} />
              </Route>
              <Route path='/dashboard' element={<Dashboard />} />
            </Routes>
          </Language.Provider>
        </BrowserRouter>
      </MantineProvider>
    </>
  )
}

export default App
