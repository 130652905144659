import React, { useContext, useEffect, useState, useRef } from 'react'
import { useForm } from '@mantine/form'
import { motion } from 'framer-motion'

import axios from '../../tools/axiosWrapper'
import { Language } from '../../context/language'
import MapCard from './cards/map/mapcard'
import InfoCard from './cards/infocard/infocard'
import ContactCard from './cards/contactcard/contactcard'
import OutputCard from './cards/outputcard/outputcard'
import inputs from '../../media/data/inputs.json'
import './lookup.css'
import './cards/card.css'
import _ from 'lodash'
import abbrevs from './stateabbrev.json'

const Lookup = ({ auth }) => {
  const { language } = useContext(Language)
  const [ins, setIns] = useState([])
  const [state, setState] = useState('Ohio')
  const [_src, set_src] = useState(null)
  const [metastate, setMetaState] = useState(null)
  const [identity, setIdentity] = useState(null)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    axios.get('/state50/voter_state_information').then((res) => {
      const { data } = res
      setMetaState(data)
    })
    if (localStorage.getItem('vf_temp') !== null) {
      const temp = JSON.parse(localStorage.getItem('vf_temp'))
      form.setFieldValue(
        'firstname',
        temp.firstname != '' ? temp.firstname : ''
      )
      form.setFieldValue('lastname', temp.lastname != '' ? temp.lastname : '')
      form.setFieldValue(
        'dd',
        temp.birthdate != '' ? temp.birthdate.substring(6, 8) : ''
      )
      form.setFieldValue(
        'mm',
        temp.birthdate != '' ? temp.birthdate.substring(4, 6) : ''
      )
      form.setFieldValue(
        'yyyy',
        temp.birthdate != '' ? temp.birthdate.substring(0, 4) : ''
      )
      form.setFieldValue('zip5', temp.zip5 != '' ? temp.zip5 : '')
      form.setFieldValue('sms', temp.sms != '' ? temp.sms : '')
      form.setFieldValue('email', temp.email != '' ? temp.email : '')
      form.setFieldValue('state', temp.state != '' ? temp.state : '')
      form.setFieldValue(
        'state2letter',
        temp.state2letter != '' ? temp.state2letter : ''
      )
      form.setFieldValue('county', temp.county != '' ? temp.county : '')
      setState(temp.state)
    }

    // if (auth) {
    //   axios.get('/state50/voter_lookup_last_encrypt').then((res) => {
    //     setIdentity(res.data.info.addressinfo ? res.data : null)
    //   })
    // }
  }, [])

  // useEffect(() => {
  //   axios.get('/state50/voter_lookup_last_encrypt').then((res) => {
  //     setIdentity(res.data.info.addressinfo ? res.data : null)
  //   })
  // }, [auth])

  useEffect(() => {
    var state2 = _.invert(abbrevs)
    form.setFieldValue('state', state ? state : '')
    form.setFieldValue('state2letter', state ? state2[state] : '')
    form.setFieldValue('county', '')
  }, [state])

  const form = useForm({
    initialValues: {
      birthdate: '',
      firstname: '',
      lastname: '',
      zip5: '',
      county: '',
      sms: '',
      state: 'Ohio',
      email: '',
      state2letter: '',
      ssn4: '',
      city: '',
      stateid: '',
      pref: 'email',
    },
  })

  const SELECTSTYLES = {
    backgroundColor: 'transparent',
    height: 50,
    fontFamily: "'Tungsten A', 'Tungsten B'",
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '40px',
  }
  const track = (values) => {
    var state2 = _.invert(abbrevs)
    form.setFieldValue('state2letter', state ? state2[state] : '')
    setProgress(1)
    setIdentity(null)
    window.scroll(0, 500)
    let month = values.mm.length == 1 ? '0' + values.mm : values.mm
    let day = values.dd.length == 1 ? '0' + values.dd : values.dd
    values.birthdate = values.yyyy + month + day
    localStorage.setItem('vf_temp', JSON.stringify(values))
    // if (contact) {
    //   axios.post('/state50/voter_preferences_encrypt/', values)
    // }

    axios
      .post('/state50/voter_register50/', values)
      .then((res) => {
        return axios.get('/state50/voter_lookup50_now/')
      })
      .then((res) => {
        const denied =
          res.data.split(' ')[7] == 'immediately' || res.data == '' ? 1 : null
        setIdentity(denied)

        var ret =
          denied !== 1 ? axios.get('/state50/voter_lookup_last_encrypt/') : 'no'
        setProgress(4)
        return ret
      })
      .then((res) => {
        setProgress(5)
        setIdentity(
          res == 'no' || res.data.info.addressinfo == '' ? 'no' : res.data
        )
      })
  }

  // useEffect(() => {
  //   if (auth && !identity) {
  //     track(form.values)
  //   }
  // }, [])

  const redirect = async (values) => {
    // setProgress(1)
    let month = values.mm.length == 1 ? '0' + values.mm : values.mm
    let day = values.dd.length == 1 ? '0' + values.dd : values.dd
    values.birthdate = values.yyyy + month + day
    localStorage.setItem('vf_temp', JSON.stringify(values))
    axios.get('/auth/login').then((res) => {
      let url = res.data.login_url
      url = url.replace('/login', '/signup')
      window.location = url
    })
  }

  const focus = {
    whileFocus: {
      boxShadow: '0px 0px 3px #AEB4C2',
      background: 'white',
    },
  }

  return (
    <>
      <form
        className={'map'}
        onSubmit={form.onSubmit((values) =>
          auth ? track(values, 1) : redirect(values)
        )}
      >
        <motion.div className={'map-flex'}>
          <MapCard
            state={state ? state : ''}
            setState={setState}
            form={form}
            SELECTSTYLES={SELECTSTYLES}
          />
          <InfoCard
            language={language}
            form={form}
            auth={auth}
            metastate={metastate}
            track={track}
            focus={focus}
            abbrevs={abbrevs}
            state={state}
            setProgress={setProgress}
            setIdentity={setIdentity}
          />
          <motion.div className={'activate'}>
            <motion.div
              className={'card-animation'}
              initial={{ width: '50%' }}
              animate={{
                width: identity == 'no' ? '100%' : progress * 20 + '%',
              }}
            ></motion.div>
          </motion.div>
          <ContactCard
            language={language}
            form={form}
            focus={focus}
            auth={auth}
          />

          {identity && (
            <OutputCard
              auth={auth}
              language={language}
              identity={identity}
              url={
                metastate && form.values.state2letter != ''
                  ? metastate[form.values.state2letter].stateurl
                  : ''
              }
            />
          )}

          <motion.div className='card-main'>
            <motion.div className='card-input' style={{ marginTop: 55 }}>
              <motion.input
                type='submit'
                value={auth ? 'check me now' : 'activate'}
                className={'lookup'}
                whileTap={{ scale: 0.95, opacity: 0.9 }}
                initial={{ scale: 1 }}
                animate={{ scale: auth && !identity ? 1.1 : 1 }}
                exit={{ scale: auth && !identity ? 1.1 : 1 }}
                style={{ background: auth && !identity ? '#83E283' : '' }}
                transition={{
                  repeat: auth && !identity ? Infinity : 1,
                  duration: 0.4,
                  repeatType: 'mirror',
                }}
              />
            </motion.div>
          </motion.div>
        </motion.div>
      </form>
    </>
  )
}

export default Lookup
