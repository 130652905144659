import React, { useContext, useEffect, useState, useRef } from 'react'
import { SegmentedControl } from '@mantine/core'
import { motion } from 'framer-motion'
import axios from '../../../../tools/axiosWrapper'

const ContactInfoCard = ({ ins, language, form, auth, focus }) => {
  const [dirty, setDirty] = useState(false)

  useEffect(() => {
    if (localStorage.getItem('vf_temp') !== null) {
      const temp = JSON.parse(localStorage.getItem('vf_temp'))
      setDirty(
        temp.email != form.values.email || temp.sms != form.values.sms
          ? true
          : false
      )
    }
  }, [form.values])

  return (
    <>
      <motion.div className={'card-main'}>
        <div className='direction'>
          <span>3 </span>alert me of changes
        </div>
        <div className={'card-input'}>
          <div>
            <SegmentedControl
              fullWidth
              style={{
                marginTop: 20,
              }}
              styles={{
                label: {
                  textTransform: 'uppercase',
                  height: 30,
                  fontFamily: "'Tungsten A', 'Tungsten B'",
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '30px',
                  lineHeight: '30px',
                },
                root: { backgroundColor: 'transparent', zIndex: 2 },
                active: {
                  backgroundColor: '#F7F9FF',
                  boxShadow: 'none',
                },
              }}
              data={[
                { label: 'english', value: 'english' },
                { label: 'spanish', value: 'español' },
              ]}
            />
            <SegmentedControl
              fullWidth
              {...form.getInputProps('pref')}
              style={{
                marginTop: 20,
              }}
              styles={{
                label: {
                  textTransform: 'uppercase',
                  height: 30,
                  fontFamily: "'Tungsten A', 'Tungsten B'",
                  fontStyle: 'normal',
                  fontWeight: 600,
                  fontSize: '30px',
                  lineHeight: '30px',
                },
                root: { backgroundColor: 'transparent', zIndex: 2 },
                active: {
                  backgroundColor: '#F7F9FF',
                  boxShadow: 'none',
                },
              }}
              data={[
                { label: 'email', value: 'email' },
                { label: 'sms', value: 'sms' },
              ]}
            />

            {form.values.pref == 'email' && (
              <div className={'labelwrap'}>
                <div className={'label'}>
                  <label>{'email'}</label>
                  <motion.input
                    {...focus}
                    required
                    pattern='*@*'
                    type='email'
                    {...form.getInputProps('email')}
                  />
                </div>
              </div>
            )}
            {form.values.pref == 'sms' && (
              <div className={'labelwrap'}>
                <div className={'label'}>
                  <label>{'phone'}</label>
                  <motion.input
                    {...focus}
                    required
                    type='tel'
                    // pattern='[0-9]{3}-[0-9]{2}-[0-9]{3}'
                    {...form.getInputProps('sms')}
                  />
                </div>
              </div>
            )}
          </div>
          {((form.values.sms.length == 10 && form.values.pref == 'sms') ||
            (form.values.email != '' && form.values.pref == 'email')) && (
            <motion.button
              type='submit'
              className={'lookup'}
              whileTap={{ scale: 0.95, opacity: 0.9 }}
              initial={{ scale: 1 }}
              style={{ background: '', display: auth ? 'block' : 'none' }}
              onClick={() => {
                if (form.values.pref == 'email') {
                  axios.post('/state50/voter_flare_welcome_email/', {
                    email: form.values.email,
                  })
                }
                if (form.values.pref == 'sms') {
                  axios.post('/state50/voter_flare_welcome_sms/', {
                    phone: '+1' + form.values.sms,
                  })
                }
              }}
            >
              send test flare
            </motion.button>
          )}

          {dirty && (
            <div
              onClick={form.onSubmit((values) => {
                axios.post('/state50/voter_preferences_encrypt/', values)
                // .then((res) => console.log(res))
              })}
              className={'update'}
            >
              update
            </div>
          )}
        </div>
      </motion.div>
    </>
  )
}
export default ContactInfoCard
