import React, { useContext, useEffect, useState, useRef } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import _ from 'lodash'

const OutputInfoCard = ({ identity, url }) => {
  let outputs = [
    {
      label: 'name',
      key: 'nameinfo',
      replace: 'Name (First Middle Last Suffix): ',
      split: 0,
    },
    {
      label: 'address',
      key: 'addressinfo',
      replace: 'Residential address: ',
      split: 0,
    },
    {
      label: 'date of birth',
      key: 'ageinfo',
      replace: 'Date of birth (yyyy-mm-dd):',
      replace1: 'Date of birth (yyyymmdd):',
      split: 1,
    },
    {
      label: 'party affiliation',
      key: 'partyinfo',
      replace: 'Party affiliation: ',
      split: 0,
    },
    {
      label: 'date of registration',
      key: 'registrationinfo',
      replace: 'Registration date: ',
      split: 0,
    },
    {
      label: 'status',
      key: 'voter_status',
      replace: 'Voter status: ',
      split: 0,
    },
    {
      label: 'state info',
      key: 'voterinfo',
      replace: '',
      split: 1,
    },
    {
      label: 'precinct info',
      key: 'precinctinfo',
      replace: '',
      split: 2,
    },
  ]
  return (
    <>
      <div className={'card-main'}>
        <div className='direction'>
          <span>4 </span> see results
        </div>
        <motion.div
          className={'card-input'}
          style={{
            position: 'relative',
            backgroundColor: identity == 'no' ? '#890024' : '#dde3f0',
          }}
        >
          {identity === 'no' && (
            <>
              <motion.div className={'no'}>
                no matching records
                <br />
                <br />
                1. double check your info
                <br />
                2. refer to your state's website
                <br />
                <a
                  style={{ color: '#B6C6FF' }}
                  href={'https://' + url}
                  target={'_blank'}
                >
                  here
                </a>
              </motion.div>
            </>
          )}
          {identity.changes &&
            identity.changes.status == 'Changes found.' &&
            _.entries(identity.changes).map((d, i) => (
              <motion.div
                className={'output-line'}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: i * 0.1 }}
              >
                <div className={'main-label output-text'}>{d[0]}</div>
                <div
                  className={'main-label output-item output-text'}
                  // style={{ height: d.key == 'addressinfo' ? '4em' : '1em' }}
                >
                  {d[1]}
                </div>
              </motion.div>
            ))}
          {identity &&
            identity !== 'no' &&
            outputs.map((d, i) => (
              <motion.div
                className={'output-line'}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: i * 0.1 }}
              >
                <div className={'main-label output-text'}>{d.label}</div>
                <div
                  className={'main-label output-item output-text'}
                  // style={{ height: d.key == 'addressinfo' ? '4em' : '1em' }}
                >
                  {identity.info
                    ? identity.info[d.key]
                        .replace(d.replace, '')
                        .replace(d.replace1, '')
                    : ''}
                </div>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </>
  )
}
export default OutputInfoCard
