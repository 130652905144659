import React, { useContext, useEffect, useState, useRef } from 'react'
import Map from './map'
import { Select, createStyles } from '@mantine/core'
import counties from '../../../../media/data/counties.json'
import { motion } from 'framer-motion'

const MapCard = ({ state, setState, form, SELECTSTYLES }) => {
  return (
    <motion.div className={'card-main'}>
      <div className='direction'>
        <span>1 </span>pick state & county
      </div>
      <Map state={state} setState={setState} />
      <div className={'mapselect-select'}>
        <Select
          data={state != '' ? counties[state] : []}
          placeholder='County'
          searchable
          clearable
          nothingFound='Nothing found'
          {...form.getInputProps('county')}
          styles={{
            input: {
              marginTop: '15px',
              color: '#EE791F',
              border: 'none',
              borderRadius: 0,
              borderBottom: '4px solid gray',
              ...SELECTSTYLES,
            },
            item: {
              lineHeight: '32px',
              ...SELECTSTYLES,
            },
            selected: { height: 50 },
          }}
        />
      </div>
    </motion.div>
  )
}
export default MapCard
