import React, { useContext, useEffect, useState, useRef } from 'react'
import { Input } from '@mantine/core'

const In = ({ form, title, zip5ref }) => {
  const underline = (
    <svg width={360} height={4}>
      <rect width={360} height={4} fill={'#6F707A'}></rect>
    </svg>
  )

  // const zip5 = (
  //   <>
  //     <div className={'label'}>
  //       <label>zipcode</label>
  //       <input
  //         required
  //         ref={zip5ref}
  //         type='number'
  //         style={{ width: '60px' }}
  //         {...form.getInputProps('zip5')}
  //       />
  //     </div>
  //     {underline}
  //   </>
  // )

  const ssn4 = (
    <>
      <div className={'label label2'}>
        <label>last 4 of social</label>
        <input
          required
          type='number'
          style={{ width: '60px' }}
          {...form.getInputProps('ssn4')}
        />
      </div>
    </>
  )

  const city = (
    <>
      <div className={'label label2'}>
        <label>city name</label>
        <input
          required
          type='text'
          style={{ width: '160px' }}
          {...form.getInputProps('city')}
        />
      </div>
    </>
  )

  const streetaddress = (
    <>
      <div className={'label label2'}>
        <label>street address</label>
        <input
          required
          type='text'
          style={{ width: '220px' }}
          {...form.getInputProps('street address')}
        />
      </div>
    </>
  )

  const stateid = (
    <>
      <div className={'label label2'}>
        <label>state id</label>
        <input
          required
          type='text'
          style={{ width: '120px' }}
          {...form.getInputProps('stateid')}
        />
      </div>
    </>
  )

  let show
  switch (title) {
    case 'streetaddress':
      show = streetaddress
      break
    case 'city':
      show = city
      break
    case 'ssn4':
      show = ssn4

      break
    case 'stateid':
      show = stateid
      break
  }
  return <>{show}</>
}

export default In
